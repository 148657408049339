<template>
  <div class="pa-2">
    <v-subheader>Impostazioni Principali</v-subheader>
    <v-divider class="pa-2"></v-divider>
    <CardList
      :cards="settingUtilities"
      identifier-key="key"
      :finishLoading="true"
      show-load-more-in="none"
      :cardsClickable="true"
    >
      <template v-slot:card="{ card }">
        <v-card
          class="border-default mx-2 my-2 d-flex flex-column"
          @click="handleListClick(card)"
          dark
          :disabled="isOnlyWheelOfFortune"
        >
        <div
            v-if="Array.isArray(card.text)"
            class="text-center text-h6"
            style="margin-bottom: -28px"
          >
            <div v-for="t in card.text" :key="t">
              {{ t }}
            </div>
          </div>
          <div v-else class="text-center text-h6 pt-2" @click="handleListClick(card)">
            {{ card.text }}
          </div>
          <div
            style="height: 100px"
            class="flex-grow-1 d-flex justify-center align-center"
          >
            <v-icon x-large>{{ card.icon }}</v-icon>
          </div>
        </v-card>
      </template>
    </CardList>
    <StandardDialog
      v-model="showBarcode"
      title="Visualizza Analitiche"
      dialog-max-width="500px"
      persistent
    >
      <div>
        <v-row>
          <v-col>
            <ManualBarcodeInput
              v-model="analyticsBarcode"
              @confirm="viewAnalyticsPermission"
            >
            </ManualBarcodeInput>
          </v-col>
        </v-row>
      </div>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="$router.push('/')"
        >
          Chiudi
        </v-btn>
      </template>
    </StandardDialog>
    <div id="free-space"></div>
  </div>
</template>

<script>
import { CardList, SearchWithButton } from "likablehair-ui-components";
import settingService from "@/services/settings/settings.service.js";
import CollapsableList from '@/components/common/CollapsableList'
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import StandardDialog from '@/components/common/StandardDialog.vue';
import operatorService from '@/services/operators/operators.service.js';
import CookieService from "@/services/cookies.service";
import UrlKeeper from '@/services/UrlKeeper.js'

export default {
  name: "AnalyticsHome",
  components: {
    CollapsableList,
    TitleWithBackButton,
    CardList,
    SearchWithButton,
    ManualBarcodeInput,
    StandardDialog
  },
  data: function() {
    return {
      settingList: [],
      analyticsBarcode: undefined,
      showBarcode: false,
      currentUser: undefined,
      isOnlyWheelOfFortune: undefined,
    }
  },
  mounted() {
    if (this.$route.params.showBarcode != undefined)
      this.showBarcode = this.$route.params.showBarcode
    this.loadAnalytics();
  },
  methods: {
    goToAnalytic(analytic) {
      this.$router.push({ name: analytic.route })
    },
    async handleListClick(section) {
      this.goTo(section.listRoute);
    },
    goTo(routeName) {
      if (routeName == "wheelOfFortune") {
        let form = document.createElement('form');
        form.target = '_blank';
        form.method = 'POST';
        form.action = UrlKeeper.getUrl('wheelOfFortune');

        let licenseUid = document.createElement('input');
        licenseUid.name = 'licenseUid';
        licenseUid.value = this.currentUser.licenses[0].uid;
        licenseUid.hidden = true;

        let authUid = document.createElement('input');
        authUid.name = 'authUid';
        authUid.value = CookieService.get('user-id');
        authUid.hidden = true;

        let authToken = document.createElement('input');
        authToken.name = 'authToken';
        authToken.value = CookieService.get('auth-token');
        authToken.hidden = true;

        form.appendChild(licenseUid);
        form.appendChild(authUid);
        form.appendChild(authToken);

        document.getElementById('free-space').innerHTML = '';
        document.getElementById('free-space').appendChild(form);
        form.submit();
      }
      else
        this.$router.push({ name: routeName }).catch(() => {});
    },
    loadAnalytics() {
      settingService.cardList().then((data) => {
        this.settingList = data;
      });
    },
    viewAnalyticsPermission() {      
      operatorService.canPerformOperation(this.analyticsBarcode, "Ghost").then((result) => {
        if (result) {
          
        } else {
          this.$router.push({path: '/'})
          alert("Non hai i permessi per eseguire questa azione!")    
        }
      }).catch(() => {
        this.$router.push({path: '/'})
        alert("Badge non riconosciuto")
      })
      this.showBarcode = false;
      this.handleTimeLogBarcode = undefined;
    },
  },
  computed: {
    settingUtilities() {
      return this.settingList.filter((el) => {
        if(el.name == 'developer' && (!this.currentUser || !this.currentUser.system))
          return false
        if ((el.name == 'wheelOfFortune') && !this.viewWheelOfFortune.active)
          return false;

        return true
      });
    },
  },
  currentUser: {
    bind: 'currentUser'
  },
  licenseSettings: {
    viewWheelOfFortune: {
      bind: "viewWheelOfFortune"
    }
  },
  isOnlyWheelOfFortune: {
    bind: 'isOnlyWheelOfFortune'
  }
}
</script>

<style scoped>
.border-default {
  padding: 5px;
  border-radius: 10px;
  border: 0px;
  transition: all 0.2s;
  transform: scale(1);
  background-image: linear-gradient(rgb(138, 119, 226, 0.8), rgb(80, 191, 225, 0.8));
  width: 200px;
  height: 190px;
  background-color: white;
}
.border-default:hover {
  padding: 5px;
  border-radius: 20px;
  border: 0px;
  transition: all 0.2s;
  transform: scale(1.05);
  z-index: 10;
}
</style>